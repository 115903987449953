import * as React from 'react';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { AsyncCatalog } from 'components/AsyncCatalog';

const CatalogPage = () => (
  <Layout>
    <SEO title="Каталог" />
    <div className="pc-row">
      <Breadcrumbs />
    </div>
    <AsyncCatalog />
  </Layout>
);

export default CatalogPage;
